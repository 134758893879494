import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';

import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      boxShadow: ''
    },
    form: {
      display: 'none',
      alignItems: 'center'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
    displayName: {
      marginLeft: '2.2em',
      minWidth: '200px',
      fontWeight: 600,
    },
    logo: {
      marginRight: '2em'
    }
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { roomInfo } = useAppState();
  const roomState = useRoomState();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar>
        <div className={classes.logo}>
          <img src="/images/mya-logo-white.svg" alt="MYA Logo" />
        </div>

        {roomState === 'connected' && roomInfo && (
          <span>Videoconsultatie met <strong>{roomInfo.data.client}</strong> voor uw afspraak van <strong>{moment(roomInfo.data.start).format('dddd LL')} om {moment(roomInfo.data.start).format('HH:mm')}</strong></span>
        )}

        <ToggleFullscreenButton />
        <Menu />
      </Toolbar>
    </AppBar>
  );
}
