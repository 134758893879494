import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Close from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndRoomButton() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { closeRoom } = useAppState();

  const handleEndCall = () => {
    closeRoom(room.name)
      .then(res => {
        room.disconnect();
      });
  };

  return (
    <Tooltip title={'End Room'} onClick={handleEndCall} placement="top" PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} color="primary">
        <Close />
      </Fab>
    </Tooltip>
  );
}
