import React, { useState, useEffect, FormEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import moment from 'moment';
import 'moment/min/locales';

import 'bootstrap/dist/css/bootstrap.min.css';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '7rem',
      backgroundColor: '#033f6c',
      padding: '1.6rem'
    },
    loadingSpinner: {
      marginTop: '1em',
      color: 'white'
    },
    info: {
      '& .title': {
        fontSize: '1.8rem',
        fontWeight: 'bold',
        marginBottom: '15px'
      },
      '& .timestamp': {
        fontWeight: 'bold',
        fontSize: '1.1rem'
      },
      '& p.remark': {
        backgroundColor: '#009fe3',
        padding: '15px'
      },
      '& p.remark-error': {
        backgroundColor: 'red',
        padding: '15px',
        textAlign: 'center'
      },
      '& p.remark-warning': {
        backgroundColor: 'orange',
        padding: '15px',
        textAlign: 'center'
      },
      '& p': {
        marginTop: '1.6rem',
        marginBottom: 0
      },
      '& button': {
        marginTop: '10px',
        padding: '10px 15px',
      }
    }
  })
);

export default function LocalVideoPreview() {
  const classes = useStyles();
  const { localTracks } = useVideoContext();
  const { URLRoomName, URLIdentityName, URLLevel } = useParams();
  const { user, findRoom, isFetching, roomInfo } = useAppState();
  const { isConnecting, connect } = useVideoContext();
  const roomState = useRoomState();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [level, setLevel] = useState<string>('');

  moment.locale('nl');

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
    }

    if (URLIdentityName) {
      setName(decodeURIComponent(URLIdentityName.replace(/\+/g, ' ')))
    }

    if (URLLevel) {
      setLevel(URLLevel);
    }
  }, [URLRoomName, URLIdentityName, URLLevel]);

  let reloadInterval = setInterval(() => {
    if (roomInfo && !roomInfo.data.user) {
      window.history.go(0)
    }
  }, 60 * 1000);

  const loadRoom = () => {
    if (isConnecting || isFetching || !name || !roomName) {
      return;
    }

    // If this app is deployed as a twilio function, don't change the URL beacuse routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      let url = `/room/${roomName}/identity/${name}`;
      if (level) {
        url += `/level/${level}`;
      }
      window.history.replaceState(null, '', window.encodeURI(url));
    }

    findRoom(name, roomName)
      .then(result => {
        if (result.data.user && moment() >= moment(result.data.activeFrom)) {
          clearInterval(reloadInterval);
          connect(result.data.user.accessToken);
        }
      })
      .catch(err => { });
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (roomInfo) { return; }

    loadRoom();
  };

  const handleClick = () => {
    setTimeout(() => {
      loadRoom();
    }, 300);
  };

  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  if (name && roomName && !isFetching && !isConnecting && roomState === 'disconnected') {
    setTimeout(() => {
      let form = document.getElementById('room-connection-form');
      if (form) {
        if (typeof (Event) === 'function') {
          form.dispatchEvent(new Event('submit', { bubbles: true }));
        } else {
          let event = document.createEvent('Event');
          event.initEvent('submit', true, true);
          form.dispatchEvent(event);
        }
      }
    }, 300);
  }

  return (
    <Container className={classes.container}>
      <form id="room-connection-form" onSubmit={handleSubmit}></form>
      {roomInfo ?
        (roomInfo.status === 'error' ?
          (<Row>
            <Col lg={12} className={classes.info}>
              <p className="remark-error">
                {roomInfo.message}
              </p>
            </Col>
          </Row>)
          :
          (<Row>
            <Col lg={6} className={classes.info}>
              <span className="title">Dag {name},</span><br />
              <p>Je hebt een videoconsultatie met</p>
              <span className="title">{roomInfo.data.client}</span><br />
              <span className="timestamp">{moment(roomInfo.data.start).format('dddd LL')} om {moment(roomInfo.data.start).format('HH:mm')}</span>
              <hr />

              {moment() >= moment(roomInfo.data.activeFrom) ?
                (
                  <>
                    <p>Je hebt toegang tot de toegewijde ruimte via onderstaande knop</p>
                    {(!isConnecting && !isFetching) && <Button variant="primary" onClick={handleClick}>Start video afspraak</Button>}
                    {(isConnecting || isFetching) && (<><CircularProgress className={classes.loadingSpinner} /> <span>Verbinding maken ...</span></>)}
                  </>
                ) :
                (
                  <>
                    <p>Je krijgt toegang tot de toegewijde videoruimte voor uw videoconsultatie op</p>
                    <span className="timestamp">{moment(roomInfo.data.activeFrom).format('dddd LL')} om {moment(roomInfo.data.activeFrom).format('HH:mm')}</span>
                    <p className="remark">
                      Dit scherm zal zich automatisch iedere minuut refreshen tot de afspraak gestart kan worden.<br />
                      Hierboven verschijnt een knop om de afspraak te starten.
                    </p>
                  </>
                )
              }
            </Col>
            <Col lg={6} style={{ position: 'relative' }}>
              {moment() >= moment(roomInfo.data.activeFrom) ?
                (videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null) :
                (<img src="/images/mya-koffie.png" alt="MYA Koffie" style={{ width: '65%', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, margin: 'auto' }} />)}
            </Col>
          </Row>))
        :
        (!name || !roomName ?
          (<Row>
            <Col lg={12} className={classes.info}>
              <p className="remark-warning">
                De dokter heeft de videoconsultatie afgesloten.
            </p>
            </Col>
          </Row>)
          :
          (<CircularProgress className={classes.loadingSpinner} />)
        )
      }
    </Container >
  );
}
